<template>
  <v-container class="px-16" fluid>
    <v-row class="mt-5" dense>
      <v-col cols="12">
        <v-card-title class="text-h5">{{ branches.length }} {{
            $vuetify.lang.t('$vuetify.pages.search.results')
          }}
        </v-card-title>
      </v-col>
    </v-row>
    <v-row v-if="displayMobileFilters" align="center" justify="space-between">
      <v-col cols="8" md="3" sm="4">
        <kurcc-sort-dropdown/>
      </v-col>
      <v-col cols="3" md="1" offset="1" sm="2">
        <kurcc-sort-and-filter-dialog/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="!displayMobileFilters" lg="4" xl="2">
        <kurcc-sort-and-filter/>
      </v-col>
      <v-col lg="8" xl="10">
        <v-row :align-content="branches.length ? 'space-between' : 'end'" class="fill-height" dense>
          <v-col v-for="(branch, index) in branches" :key="index" cols="12" md="6">
            <kurcc-site-card :branch="branch"/>
          </v-col>
          <v-col align-self="baseline" class="mt-6" cols="10" lg="9" md="7" sm="6" xl="10">
            <v-pagination v-model="options.page" :length="totalPages" circle total-visible="7"/>
          </v-col>
          <v-col :style="{height: 'fit-content'}" class="mt-6">
            <v-select v-model="options.itemsPerPage" :items="[1, 2, 10,25,50,100]"
                      :label="$vuetify.lang.t('$vuetify.general.perPage')"
                      :loading="loadingBranches" align-self="baseline" dense hide-details outlined persistent-hint/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SET_IS_SEARCHING } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccHomePageSearchResult',
  components: {
    KurccSortDropdown: () => import('@/modules/home/components/KurccSortDropdown'),
    KurccSiteCard: () => import('@/modules/home/components/KurccSiteCard'),
    KurccSortAndFilter: () => import('@/modules/home/components/KurccSortAndFilter'),
    KurccSortAndFilterDialog: () => import('@/modules/home/components/KurccSortAndFilterDialog')
  },
  data () {
    return {
      branches: [],
      options: {
        page: 1,
        itemsPerPage: 10
      },
      totalPages: 0,
      loadingBranches: false,
      debounceTimeout: null
    }
  },
  computed: {
    displayMobileFilters () {
      return !this.$vuetify.breakpoint.lgAndUp
    },
    searchQuery () {
      return this.$store.state.search.query
    },
    isSearching: {
      get () {
        return this.$store.state.search.isSearching
      },
      set (v) {
        this.$store.commit(SET_IS_SEARCHING, v)
      }
    },
    filter () {
      return this.$store.state.filter
    }
  },
  watch: {
    searchQuery: function () {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.isSearching = true
        this.getAllBranches()
      }, 1000)
    },
    filter: {
      handler: function () {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout)
        }

        this.debounceTimeout = setTimeout(() => {
          this.isSearching = true
          this.getAllBranches()
        }, 1000)
      },
      deep: true
    },
    options: {
      handler: function () {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout)
        }

        this.debounceTimeout = setTimeout(() => {
          this.isSearching = true
          this.getAllBranches()
        }, 1000)
      },
      deep: true
    }
  },
  methods: {
    getAllBranches () {
      const {
        itemsPerPage: perPage,
        page: currentPage
      } = this.options

      this.loadingBranches = true
      this.$store.dispatch('getAllBranches', {
        perPage,
        currentPage,
        withIncludes: true,
        searchQuery: this.searchQuery,
        orderBy: this.filter.orderBy,
        sortedBy: this.filter.sortedBy,
        additionalParams: this.getAdditionalParams()
      }).then(res => {
        this.branches = res.items
        this.totalPages = res.pagination.total_pages
      }).finally(() => {
        this.loadingBranches = false
        this.isSearching = false
      })
    },
    getAdditionalParams () {
      const filters = []
      const categoryIds = this.$lodash.map(this.filter.categories, category => `"${category.id}"`)
      const brandIds = this.$lodash.map(this.filter.brands, site => `"${site.id}"`)
      const areaIds = this.$lodash.map(this.filter.areas, area => `"${area}"`)
      if (categoryIds.length) {
        filters.push(`category_ids=[${categoryIds}]`)
      }
      if (brandIds.length) {
        filters.push(`site_ids=[${brandIds}]`)
      }
      if (areaIds.length) {
        filters.push(`areas=[${areaIds}]`)
      }
      return filters
    }
  },
  created () {
    this.getAllBranches()
  }
}
</script>
